import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from 'src/environments/environment'
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponseData } from '../models/ResponseData';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private baseUrl: string = ''
  
  constructor(
    private httpClient: HttpClient
  ) { 
      this.baseUrl = environment.apiUrl
  }

  get<T>(url: string, id?: number | boolean, params?: any, headers?: any): Observable<T> {
    const options = {};
    if (params) {
      options['params'] = params;
    }
    if (headers) {
      options['headers'] = headers;
    }

    if (id !== null && id !== undefined) {
      return this.httpClient.get<T>(`${url}/${id}`, options);
    } else {
      return this.httpClient.get<T>(`${url}`, options);
    }
  }
  getwithNoLoader<T>(url: string, id?: number | boolean, params?: any, headers?: any): Observable<T> {
    const options = {};
    if (!headers) {
      headers = [];
    }
    headers = new HttpHeaders().set('hideLoader', 'true');

    if (params) {
      options['params'] = params;
    }
    if (headers) {
      options['headers'] = headers;
    }


    if (id !== null && id !== undefined) {
      return this.httpClient.get<T>(`${url}/${id}`, options);
    } else {
      return this.httpClient.get<T>(`${url}`, options);
    }
  }
  post<T>(url: string, data: any): Observable<T> {
    return this.httpClient.post<T>(`${url}`, data);
  }
  postNew(url: string, params: any = null, oldModel: any = null): Observable<ResponseData> {

    // this.loaderService.show();
    return this.httpClient.post(url, params).pipe(map((response: ResponseData) => {
      if (response != null) {
        // this.loaderService.hide();
        return response
      }
      else {
        throw new Error('Valid token not returned');
      }
    }, err => {
      // this.loaderService.hide();
    }));
  }
  put<T>(url: string, data: any): Observable<T> {
    return this.httpClient.put<T>(`${url}`, data);
  }

  delete<T>(url: string, id: number): Observable<T> {
    return this.httpClient.delete<T>(`${url}/${id}`);
  }
  deleteWithoutLoader<T>(url: string, id: number): Observable<T> {
    const options = {};
    var headers = new HttpHeaders().set('hideLoader', 'true');
    options['headers'] = headers;
    return this.httpClient.delete<T>(`${url}/${id}`, options);
  }
  patch<T>(url: string, id: number, data?: any): Observable<T> {
    return this.httpClient.patch<T>(`${this.baseUrl}${url}/${id}`, data);
  }
}
