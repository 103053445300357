import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from './data.service';

 
export abstract class BaseApiService<T> {
  constructor(protected ctrl: string, protected dataService: DataService) {}

  getOneById(id: number): Observable<T> {
    return this.dataService.get<T>(this.ctrl, id);
  }
  public getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
}
  public getIPAddress(): Observable<any> {
    return this.dataService.get<any>('https://api.ipify.org/?format=json');
  }
  public getAll(): Observable<T[]> {
    return this.dataService.get<T[]>(this.ctrl);
  }

  public save(data: T, id: number): Observable<number> {
    if (id > 0) {
      return this.dataService.put<number>(this.ctrl, data);
    } else {
      return this.dataService.post<number>(this.ctrl, data);
    }
  }

  public delete(id: number): Observable<void> {
    return this.dataService.delete<void>(this.ctrl, id);
  }

  public saveUpdate(data: T, id: number): Observable<number> {
      return this.dataService.post<number>(this.ctrl, data);
  }

  public deleteresponse(id: number): Observable<any> {
    return this.dataService.delete<any>(this.ctrl, id);
  }
}

